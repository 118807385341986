@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Carter+One&family=Cinzel+Decorative&family=Indie+Flower&family=Lobster&family=Lobster+Two&family=Poiret+One&family=Quicksand:wght@300&family=Tapestry&family=Montserrat&display=swap');

/* cyrillic-ext */
@font-face {
    font-family: 'Alumni Sans Pinstripe';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDaL9JlcA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Alumni Sans Pinstripe';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDYb9JlcA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Alumni Sans Pinstripe';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDar9JlcA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Alumni Sans Pinstripe';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDa79JlcA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Alumni Sans Pinstripe';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/alumnisanspinstripe/v1/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDZb9J.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* Quicksand */
  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

  /* Open Sans */
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
  

.swal2-container {
  z-index: 9999;
}

.leaflet-container {
  width: 100%;
  height: inherit;
  border-radius: 10px;
}

.statusScreenBrick_container h1, 
.statusScreenBrick_container span,
.statusScreenBrick_container p {
  font-family: 'Quicksand';
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Estilo da barra de rolagem horizontal */
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

::-webkit-scrollbar-track {
  background-color: #272727; /* Estilo da barra de rolagem horizontal */
}

/* Firefox */
/* A partir do Firefox 64, a barra de rolagem é exibida apenas quando o usuário interage */
/* Para forçar a exibição da barra de rolagem no Firefox, você pode adicionar o estilo abaixo */
/* Se preferir a exibição apenas durante a interação, remova esse trecho */
html {
  scrollbar-width: thin;
  scrollbar-color: #000 #272727;
}

html::-moz-scrollbar {
  width: 8px;
  height: 8px; /* Estilo da barra de rolagem horizontal */
}

html::-moz-scrollbar-thumb {
  background-color: #000;
  border-radius: 4px;
}

html::-moz-scrollbar-thumb:hover {
  background-color: #000;
}

html::-moz-scrollbar-track {
  background-color: #272727; /* Estilo da barra de rolagem horizontal */
}

.payment-brick form {
  padding: 10px !important;
}

.detail-product-image-container .image-gallery {
  width: 100% !important;
}

.pincode-input-container input {
  border-radius: 5px;
}