@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-image {
    width: 100% !important;
    border-radius: 10px;
    max-height: 380px !important;
}

.image-gallery-thumbnail-inner img {
    border-radius: 10px;
}

button.image-gallery-thumbnail {
    width: max-content !important;
    border-radius: 15px;
}

button.image-gallery-thumbnail.active {
    width: max-content !important;
    border-radius: 15px;
}

button.image-gallery-thumbnail:hover {
    width: max-content !important;
    border-radius: 15px;
}

.image-gallery-thumbnail-image {
    width: 40px !important;
    cursor: pointer;
}

.image-gallery-thumbnails {
    overflow-x: auto;
}